import React, { Component } from 'react';
import { BasePage } from './BasePage';
import { withNamespaces } from 'react-i18next';
import { WindwowProvider } from '../common/window-proivider';

export interface ClientsProps {
    clients: any[];
}

export class ClientsPage extends Component<ClientsProps> {
    public render() {
        return (
            <main id="clients">
                {WindwowProvider.isSmallScreen() && <div className="mobile-title" role="title">Aleksandar Novoselski</div>}
                <div className="clients-holder">
                {
                    this.props.clients.map((c, i) => {
                        return (<div className={`client ${WindwowProvider.isSmallScreen() ? "small": ""}`} key={`client-${i}`}>
                            <img src={c.src} />
                        </div>);
                    })
                }
                </div>
            </main>
        )
    }
}
