import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Sidebar from "react-sidebar";
import { WindwowProvider } from '../../common/window-proivider';

export interface SideMenuState {
    sidebarOpen: boolean;
    isTop: boolean;
}

export class SideMenu extends Component<any, SideMenuState> {
    constructor(p, pp) {
        super(p, pp);

        this.state = {
            sidebarOpen: false,
            isTop: true
        };

        new WindwowProvider().scrollChanged.addListener("", this.scolled.bind(this));
    }

    sections = [
        { title: "Architecture" },
        { title: "Hospitality" },
        { title: "Public" },
        { title: "Residential" },
        // { title: "Projects" },
        { title: "Clients" },
        // { title: "About" },
        { title: "Contact" }
    ]

    render() {
        return (
            <aside>
                { WindwowProvider.isSmallScreen() ? this.renderDrawer() : this.renderMenu()}
            </aside>
        )
    }

    private renderMenu() {
        return (
            <div id="menu">
                <div className={`title ${this.state.isTop ? "" : "-shrink"}`}>
                    <Link to="/">
                        Aleksandar Novoselski
                    </Link>
                </div>
                {this.renderMenuSections()}
            </div>
        );
    }

    private renderMenuSections() {
        return (
            <React.Fragment>

            <ul>
                {WindwowProvider.isSmallScreen() ? (
                    <li key={`side-title`} onClick={() => {this.setState({sidebarOpen: false})}}>
                            <Link to={`/`}>Home</Link>
                    </li>
                ) : null}
                {this.sections.map((s, i) => {
                    return (
                        <li key={`side-${i}`} className={s.title === "Clients" ? "gutter" : null}
                        onClick={() => {
                            this.setState({ sidebarOpen: false });
                        }}>
                            <Link to={`/${s.title}`}>{s.title}</Link>
                        </li>
                    )
                })}
            </ul>
            </React.Fragment>
        );
    }

    private renderDrawer() {
        return (
            <Sidebar
                styles={{ 
                sidebar: { 
                    position: "fixed",
                    background: "white"
                },
                root: {
                    position: "undefined"
                },
                content: {
                    // position: "undefined",
                    // top: "undefined",
                    // left: "undefined",
                    // right: "undefined",
                    // bottom: "undefined"
                }
            }}
                sidebar={this.renderMenuSections()}
                open={this.state.sidebarOpen}
                contentClassName="sidebar-contentClassName"
                sidebarClassName="sidebar-menu"
                onSetOpen={this.toggleDrawer.bind(this)}
            >
                <div className="button-container" onClick={() => {
                    this.toggleDrawer();
                }}> 
                    <div className="bar1"></div>
                    <div className="bar2"></div>
                    <div className="bar3"></div>
                </div>
            </Sidebar>
        )
    }

    private toggleDrawer() {
        this.setState({ sidebarOpen: !this.state.sidebarOpen });
    }

    private scolled(e) {
        this.setState({isTop: e});
    }
}
