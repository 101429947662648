import React, { Component } from 'react';
import { Social } from './Social';

export interface FooterProps {
    className: string;
}

export class Footer extends Component<FooterProps> {
    public render() {
        return(
            <footer className= {this.props.className}>
                <Social />
            </footer>
        )
    }
}
