import React, { Component } from 'react';
import logo from './logo.svg';
import './App.scss';
import { AppRouter } from './Router';

import i18n from "i18next";

import { reactI18nextModule, withNamespaces, WithNamespaces } from 'react-i18next';
import { Assets } from './assets/assets';
import { LocalStorageProvider, LKey } from './common/local-storage-cache';

i18n
  .use(reactI18nextModule) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: Assets.translations.en
      },
      bg: {
        translation: Assets.translations.bg
      }
    },
    lng: LocalStorageProvider.getItem(LKey.language) || "en",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false
    }
  });

class App extends Component<WithNamespaces> {
  render() {
    
    const { t } = this.props;
    return (
          <AppRouter t={t}>
          </AppRouter>
    );
  }
}

export default withNamespaces('translation')(App);
