import React, { Component } from 'react';
import { LightBoxWrapper } from './LigboxWrapper';
import { ImageModel } from '../../common/models';
import { WindwowProvider } from '../../common/window-proivider';


interface GridProps {
    images: ImageModel[];
    largeThumbs: ImageModel[];
    smallThumbs: ImageModel[];
}

interface GridState {
    style: any;
}

export class ImageGrid extends Component<GridProps, GridState> {
    private lightbox: LightBoxWrapper;

    constructor(p, s) {
        super(p, s);
        this.state = {
            style: null
        }
    }

    public render() {
        return (
            <div>
                <div className="grid-holder" style={this.generateGridStyle()}>
                    {this.generateGrid()}
                </div>

                <LightBoxWrapper ref={(ref) => { this.lightbox = ref; }} images={this.props.images} />
            </div>);
    }

    private generateGrid() {
        const geneator = WindwowProvider.isSmallScreen() ? new MobileLayoutGenerator() : new LayoutGenerator();

        return geneator.generate(this.props.images).map((model, i) => {
            return (
                <figure style={model.style} onClick={() => { this.openLightbox(i) }} key={`grid-photo-${i}`}>
                <ImageWrapper className="gallery__img" image={this.props.largeThumbs[i].src} thumb={this.props.smallThumbs[i].src} />
            </figure>
        )
    })
    }

    private generateGridStyle() {
        if (WindwowProvider.isSmallScreen()){
            return {
                gridTemplateColumns: "repeat(1, 100%)",
                gridTemplateRows: `repeat(${MobileLayoutGenerator.getRowsCount(this.props.images)}, 33vh)`
            };
        }
        return {
            gridTemplateColumns: "repeat(3, 33%)",
            gridTemplatRows: `repeat(${LayoutGenerator.getRowsCount(this.props.images)}, 33vh)`
        }
    }

    private openLightbox(i: number): any {
        this.lightbox.requestOpen(i);
    }
}

interface ImageWrapperProps {
    thumb: string;
    image: string;
    className: string;
}

interface ImageWrapperState {
    hasLoaded: boolean;
}

class ImageWrapper extends Component<ImageWrapperProps, ImageWrapperState> {
    constructor(p, pp) {
        super (p, pp);
        this.state = {
            hasLoaded: false
        };
    }

    render() {
        return (
            <div className="gallery__img-holder">
                {!this.state.hasLoaded &&
                    <img className={this.props.className + " thumb"}
                         src={this.props.thumb} />
                }
                <img style={this.state.hasLoaded ? null : {display: "none"}}
                     className={this.props.className}
                     onLoad={() => this.loaded()}
                     src={this.props.image} />
            </div>
    );
    }

    private loaded() {
        this.setState({hasLoaded: true});
    }
}

class MobileLayoutGenerator {    
    private cells = [];

    generate(images: ImageModel[]): {image: ImageModel, style: any}[] {
        const done: { image: ImageModel, style: any }[] = [];

        images.forEach((im, i) => {
            const c = this.getNextCell(i);
            
            const col = 1;
            const row = c;
            
            if (im.isVertical) {
                this.cells[c + 1] = 1;
                done.push({image: im, style: this.generateStyle(col, row, row + 1)});
            } else {
                done.push({ image: im, style: this.generateStyle(col, row) });
            }
        });

        return done;
    }

    getNextCell(i: number): number {
        let currentCell = 0;

        while (this.cells[currentCell]) {
            currentCell++;
        }

        this.cells[currentCell] = 1;

        return currentCell;
    }

    static getRowsCount(images: ImageModel[]): number {
        let cellsCount = 0;
        images.forEach(i => {
            cellsCount++;
            if (i.isVertical) {
                cellsCount++;
            }
        });

        return Math.ceil(cellsCount);
    }

    private generateStyle(col, row, rowEnd?) {
        const ret = {
            gridColumnStart: col,
            gridRowStart: row + 1
        }

        if (rowEnd) {
            (ret as any).gridRowEnd = rowEnd + 2;
        }

        return ret;
    }
}

class LayoutGenerator {
    private cells = [];

    generate(images: ImageModel[]): { image: ImageModel, style: any }[] {
        const done: { image: any, style: any }[] = [];

        images.forEach((im, i) => {

            const c = this.getNextCell(i);

            const col = c % 3;
            const row = Math.floor(c / 3);

            if (im.isVertical) {
                this.cells[c + 3] = 1;
                done.push({ image: im, style: this.generateStyle(col, row, row + 1) });
            } else {
                done.push({ image: im, style: this.generateStyle(col, row) });
            }
        });

        return done;
    }

    getNextCell(i: number): number {
        let currentCell = 0;

        while (this.cells[currentCell]) {
            currentCell++;
        }

        this.cells[currentCell] = 1;

        return currentCell;
    }

    static getRowsCount(images: ImageModel[]): number {
        let cellsCount = 0;
        images.forEach(i => {
            cellsCount++;
            if (i.isVertical) {
                cellsCount++;
            }
        });

        return Math.ceil(cellsCount / 3);
    }

    private generateStyle(col, row, rowEnd?) {
        const ret = {
            gridColumnStart: col + 1,
            gridRowStart: row + 1
        }

        if (rowEnd) {
            (ret as any).gridRowEnd = rowEnd + 2;
        }

        return ret;
    }
}
