import React, { Component } from 'react';
import { Footer } from '../presenters/Footer';
import { WithNamespaces } from 'react-i18next';

export class BasePage<P = WithNamespaces, S = {}, SS = any> extends Component<P, S, SS>  {
    public renderContent() {
        return null;
    }

    public render(): any {
        return [
            this.renderContent(),
            <Footer className="base-page-footer"/>
        ]
    }

    protected splitToLines(text: string) {
        const arr = text.split(/<br>|<br\/>/);
        return arr.map(p => (<p>{p}</p>));
    }
}
