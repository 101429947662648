import React, { Component } from 'react';
import { ImageHolder } from "../presenters/new/ImageHolder";
import { Languages } from "../presenters/Language";
import { Social } from "../presenters/Social";
import { Link } from "react-router-dom";
import { Assets } from '../assets/assets';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { SideMenu } from '../presenters/new/Menu';
import { LightBoxWrapper } from '../presenters/new/LigboxWrapper';
import { ImageGrid } from '../presenters/new/ImageGrid';
import { WindwowProvider } from '../common/window-proivider';

interface PageWrapperProps extends WithNamespaces {
    photos: any[];
    largeThumbs: any[];
    smallThumbs: any[];
}

class PhotoPageContent extends Component<PageWrapperProps> {
    private lighbox: LightBoxWrapper;

    public render() {
        return (
            <main className="gallery">
                {WindwowProvider.isSmallScreen() && <div className="mobile-title" role="title">Aleksandar Novoselski</div>}
                <ImageGrid images={this.props.photos} largeThumbs={this.props.largeThumbs} smallThumbs={this.props.smallThumbs}/>
            </main>
        );
    }
}

export let PhotoPageContentWrapped = withNamespaces()(PhotoPageContent);
