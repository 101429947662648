class LocalStorageCache {
    public setItem(key: LKey | string, val: string) {
        localStorage.setItem(key, val);
    }

    public getItem(key: LKey | string): string {
        return localStorage.getItem(key);
    }
}

export enum LKey {
    language = "idea-fabrika-lang"
}

export let LocalStorageProvider = new LocalStorageCache();
