import { EventEmitter } from "events";

export class WindwowProvider {
    public sizeChanged: EventEmitter = new EventEmitter();
    public scrollChanged: EventEmitter = new EventEmitter();
    
    private isTop = true;

    constructor () {
        window.addEventListener("resize", this.resized.bind(this));
        window.addEventListener("scroll", this.scrolled.bind(this));
    }

    public static readonly breakpoints = {
        s: 600,
        m: 900,
        l: 1200,
        xl: 1800
    }

    private static getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window;
        return {
            width,
            height
        };
    }

    public static isSmallScreen() {
        return this.getWindowDimensions().width <= this.breakpoints.m;
    }

    private resized() {
        this.sizeChanged.emit("");
    }

    private scrolled(e) {
        const y = e.currentTarget.scrollY
        let top = this.isTop;
        if (y < 60) {
            top = true;
        } else {
            top = false;
        }

        if (top !== this.isTop) {
            this.isTop = top;
            this.scrollChanged.emit("", top);
        }
    }
}
