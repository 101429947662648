import React, { Component } from 'react';
import Lightbox from 'react-image-lightbox';
import { ImageModel } from '../../common/models';

import "react-image-lightbox/style.css";

export interface LightboxProps {
    images: ImageModel[];
}

export interface LightboxState {
    isOpen: boolean;
    currentIndex: number;
}

export class LightBoxWrapper extends Component<LightboxProps, LightboxState> {

    constructor(p, pp) {
        super(p, pp);
        this.state = {
            isOpen: false,
            currentIndex: 0
        }
    }

    render() {
       return (
           this.state.isOpen && (
            <Lightbox
                enableZoom={false}
              mainSrc={this.props.images[this.state.currentIndex].src}
              nextSrc={this.props.images[(this.state.currentIndex + 1) % this.props.images.length].src}
              prevSrc={this.props.images[(this.state.currentIndex + this.props.images.length - 1) % this.props.images.length].src}
              onCloseRequest={() => this.setState({ isOpen: false })}
              onMovePrevRequest={() =>
                this.setState({
                  currentIndex: (this.state.currentIndex + this.props.images.length - 1) % this.props.images.length,
                })
              }
              onMoveNextRequest={() =>
                this.setState({
                  currentIndex: (this.state.currentIndex + 1) % this.props.images.length,
                })
              }
            />
          )
        );
    }

    requestOpen(currentIndex: number) {
        this.setState({isOpen: true, currentIndex});
    }

}
