import React, { Component } from 'react';
import { Assets } from '../assets/assets';

const social: SocaialItem[] = [

]

interface SocaialItem {
    link: string;
    icon: string;
}

export class Social extends Component {
    public render() {
        return (this.renderSocial());
    }

    private renderSocial() {
        const r = [];

        social.forEach((s, i) => {
            r.push((<li key={`social-${i}`} className="social-icon"><a href={s.link}><img src={s.icon}/></a></li>));
        });

        return <ul className="social">{r}</ul>;
    }
}
