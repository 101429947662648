import React, { Component } from 'react';
import { BasePage } from './BasePage';
import { WindwowProvider } from '../common/window-proivider';

export interface ContactPageState {
    mouseOverNumber: boolean
}

export class ContactPage extends BasePage<any, ContactPageState> {
    
    constructor(p){
        super(p);
        this.state = {
            mouseOverNumber: false
        };
    }
    
    public renderContent() {
        return (
            <main id="contact" className={!WindwowProvider.isSmallScreen() && "-large"}>
               <div>
                    Aleksandar Novoselski is a commercial photographer specialized in interior and architectural photography, based in Sofia, Bulgaria.
               </div>
               <div>
                   <a href="mailto:info@novoselski.com">info@novoselski.com</a>
                </div>
                <div>
                   <span>+359879345581</span>
                </div>
                <div className="social">
                    <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/aleksandar.novoselski/"><img src="./insta.png" alt=""/></a>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/novoselski.interior.photography/"><img src="./fb.png" alt=""/></a>
                </div>
            </main>
        )
    }
}
