const config = require("./configs/config.json");
const clients = require("./configs/clients.json")

export const Assets = {
    translations: {
        en : require("./translations/en.json"),
        bg : require("./translations/bg.json")
    },
    photos: processConfig(config, "1200"),
    smallThumbs: processConfig(config, "60"),
    largeThumbs: processConfig(config, "500"),
    clients: processClients(clients)
}

function processClients(conf) {
    const clients = conf.Clients;
    const res = [];

    clients.forEach(c => {
        const cl = c;
        cl.src = require(`./${c.src}`);
        res.push(cl);
    });

    return res;
}

function processConfig(inputConf, size) {
    const conf = JSON.parse(JSON.stringify(inputConf));
    for (const folder in conf) {
        if (conf.hasOwnProperty(folder)) {
            const f = conf[folder];
            conf[folder] = f.map(file => {
                const mod = file;
                mod.src = require(`./photo/${size}/${file.src}`);
                return mod;
            })
        }
    }

    return conf;
}
