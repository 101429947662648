import React, { Component, useEffect } from "react";
import { HashRouter as Router, Route, Link, Redirect, Switch } from "react-router-dom";
import { PhotoPageContentWrapped } from "./pages/Home";
import { ClientsPage } from "./pages/Clients";
import { ContactPage } from "./pages/Contact";
import { SideMenu } from "./presenters/new/Menu";
import { Assets } from "./assets/assets";

export interface AppRouterProps {
    t: any;
    children: any;
}

//scroll to top when navigate to route
function ScrollToTop() {
    useEffect(() => {
        window.scrollTo(0, 0);
    });

    return null;
}

export class AppRouter extends Component<AppRouterProps> {
    private photos = Assets.photos;
    private largeThumbs = Assets.largeThumbs;
    private smallThumbs = Assets.smallThumbs;
    private clients = Assets.clients;

    public render() {
        return (
            <Router basename="/">
                <div>
                    <ScrollToTop />
                    <SideMenu />
                    <Switch>
                    {this.generateGalleryRoute("", "_SHOWCASE")}
                    {this.generateGalleryRoute("Architecture", "_ARCHITECTURE")}
                    {this.generateGalleryRoute("Hospitality", "_HOSPITALITY")}
                    {this.generateGalleryRoute("Public", "_PUBLIC")}
                    {this.generateGalleryRoute("Residential", "_RESIDENTIAL")}

                    {/* <Route path="/Projects" exact render={(props) => <PhotoPageContentWrapped photos={this.photos[0]} {...props} />} /> */}
                    {/* <Route path="/Clients" exact render={(props) => <PhotoPageContentWrapped photos={this.photos[0]} {...props} />} /> */}
                    {/* <Route path="/About" exact render={(props) => <PhotoPageContentWrapped photos={this.photos[0]} {...props} />} /> */}
                    <Route path="/Clients" exact render={(props) => <ClientsPage clients={this.clients}/>} />
                    <Route path="/Contact" exact render={(props) => <ContactPage />} />

                    <Route path="/:lang">
                        <Redirect to="/" />
                    </Route>
                    </Switch>
                    {this.props.children}
                </div>
            </Router>
        )
    }

    private generateGalleryRoute(route: string, folder: string) {
        return (<Route path={`/${route}`} exact render={(props) => <PhotoPageContentWrapped photos={this.photos[folder]} largeThumbs={this.largeThumbs[folder]} smallThumbs={this.smallThumbs[folder]} {...props} />} />);
    }
}

